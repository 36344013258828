
import { defineComponent } from "vue";
// import List from "@/data/Ht-Tr-parameter/List.json";
import axios from "axios";

export default defineComponent({
  name: "kt-widget-12",
  components: {},
  props: {
    widgetClasses: String,
  },
  data(): {
    ApiURL: string;
    Param: any;
    startDate: string;
    endDate: string;
    Step: number;
    List: Array<any>;
    ListHeader: any;
    SpecList: Array<any>;
    Model_Id: any;
    SpecItem: any;
    SpecType: any;
    keywords: any;
  } {
    return {
      ApiURL: "https://ah.artinuro.com:8443/",
      Param: {
        Paging: {
          pageSize: 10,
          pageNumber: 1,
        },
        Search: {},
      },
      startDate: "",
      endDate: "",
      Step: 1,
      List: [],
      ListHeader: {},
      SpecList: [],
      Model_Id: {},
      SpecItem: {},
      SpecType: 0,
      keywords: "",
    };
  },
  watch: {
    keywords(newVal) {
      this.Param.Search.keywords = newVal;
      this.GetList(1);
    },
  },
  methods: {
    newGuid() {
      var guid = "";
      for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
      }
      return guid;
    },
    async Init() {
      this.GetList(1);
    },
    async GetList(val) {
      this.Param.Paging.pageNumber = val;
      axios
        .post(this.ApiURL + "Model/List", this.Param)
        .then(res => {
          this.List = res.data;
          this.ListHeader = JSON.parse(res.headers["list-header"]).Paging;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async Delete(item) {
      var yes = confirm("請確定是否刪除");
      // var a = { item };
      // console.log(a);

      if (yes) {
        axios
          .delete(this.ApiURL + "Model", {
            data: item,
            headers: { Authorization: "***" },
          })
          .then(res => {
            console.log(res);
            alert("刪除成功");
            this.Init();
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    async GetSpecList(id) {
      this.Model_Id = id;
      axios
        .post(this.ApiURL + "ModelSpec/List/" + id, {
          Paging: {
            pageSize: 999,
            pageNumber: 1,
          },
          Search: {},
        })
        .then(res => {
          this.SpecList = res.data;
          this.Step = 2;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async GetSpec(id) {
      axios
        .get(this.ApiURL + "ModelSpec/" + id)
        .then(res => {
          this.SpecItem = res.data;
          this.SpecType = 0;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async GetNewSpec() {
      this.SpecItem = {
        Id: this.newGuid(),
        Upper: 0,
        Lower: 0,
        Model_Id: this.SpecList[0].Model_Id,
        Item: "",
        Item_No: "",
        Sheet_No: "",
        Importance: "",
        Inspection_Method: "",
        Specification: 0,
        Unit: "",
        Mark: false,
        Enable: false,
        Removed: false,
        Process: "",
        IQCDetails: [],
        Model: null,
      };
      this.SpecType = 1;
      console.log(this.SpecItem);
    },
    async SaveSpec() {
      switch (this.SpecType) {
        case 0:
          console.log("put");
          axios
            .put(this.ApiURL + "ModelSpec", this.SpecItem)
            .then(res => {
              console.log(res);
              alert("修改成功");
              this.GetSpecList(this.SpecItem.Model_Id);
            })
            .catch(err => {
              console.log(err);
            });
          break;

        case 1:
          console.log("post");
          axios
            .post(this.ApiURL + "ModelSpec", this.SpecItem)
            .then(res => {
              console.log(res);
              alert("新增成功");
              this.GetSpecList(this.SpecItem.Model_Id);
            })
            .catch(err => {
              console.log(err);
            });
          break;
      }

      // switch (type) {
      //   case "put":
      //
      //     break;

      //   case "post":
      //     break;
      // }
    },
    async DeleteSpec(item) {
      var yes = confirm("請確定是否刪除");
      if (yes) {
        axios
          .put(this.ApiURL + "ModelSpec/Removed", item)
          .then(res => {
            console.log(res);
            alert("刪除成功");
            this.GetSpecList(this.Model_Id);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
  },
  created() {
    this.Init();
  },
});
